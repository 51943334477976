import CookieHelper from '@/store/services/CookieHelper'

export default class FiftySourceHelper {
  static readonly fiftySourceKey: string = 'fifty-source'
  static fiftySource(): string {
    const fiftySourceValue = CookieHelper.getCookie('fifty-source')
    if (fiftySourceValue) {
      return fiftySourceValue
    }
    return 'unknown'
  }

  private static _ios = 'ios'
  private static _android = 'android'

  public static isIos = () => FiftySourceHelper.fiftySource() === FiftySourceHelper._ios
  public static isAndroid = () => FiftySourceHelper.fiftySource() === FiftySourceHelper._android
  public static isPhoneApp = () => FiftySourceHelper.isIos() || FiftySourceHelper.isAndroid()
}
