import { routes } from './routes'

import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.query.pageScrollTopZero) {
      return { left: 0, top: 0 }
    }

    // Skip if destination full path has query parameters and differs in no other way from previous
    if (from && to.name === from.name && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] === from.fullPath.split('?')[0]) {
        return
      }
    }

    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        el: to.hash,
      }
    }

    // this happens when closing modals
    if (!to.hash && from.hash) return

    return { left: 0, top: 0 }
  },
})

export default router
