/* eslint-disable @typescript-eslint/no-namespace */
import type { Store } from 'vuex'
import type { Router } from 'vue-router'
import { COMMIT_HARD_DISCONNECT, COMMIT_SOFT_DISCONNECT } from '../../modules/Login'
import type { FiftyApiClientManager } from './FiftyApiClientManager'
import FiftySourceHelper from '@/store/services/FiftySourceHelper'
import { PAGE_LOGIN } from '@/router/routes'
import LoginManager, { RefreshResult } from '@/store/services/LoginManager'
import useFiftyBannerAlert from '@/hooks/useFiftyBannerAlert'
import { FiftyApiClients } from './generated/fiftyApiClient'

const { showUnreachableAPIAlert } = useFiftyBannerAlert()

export namespace CustomApiClientCode {
  export interface IAppConfig {
    baseUrl: string
  }

  export class BaseFiftyApiClientDependencies {
    public config: IAppConfig
    public store: Store<any>
    public router: Router
    public apiClients: FiftyApiClientManager

    constructor(config: IAppConfig, store: Store<any>, router: Router, apiClients: FiftyApiClientManager) {
      this.config = config
      this.store = store
      this.router = router
      this.apiClients = apiClients
    }
  }

  export class BaseFiftyApiClient {
    private dep: BaseFiftyApiClientDependencies
    private consecutive401 = 0
    private maxConsecutive401 = 3

    constructor(dep: BaseFiftyApiClientDependencies) {
      this.dep = dep
    }

    protected getBaseUrl(_unknown?: string, _defaultUrl?: string): string {
      return this.dep.config.baseUrl
    }

    protected transformResult(
      url: string,
      responseOriginal: Response,
      processor: (response: Response) => Promise<any>,
    ): Promise<any> {
      return processor(responseOriginal)
        .then((response: any) => Promise.resolve(response))
        .catch(async (response) => {
          if (FiftyApiClients.SwaggerException.isSwaggerException(response)) {
            if (response.status === 401) {
              this.consecutive401++

              if (this.consecutive401 >= this.maxConsecutive401) {
                this.dep.store.commit(
                  COMMIT_HARD_DISCONNECT,
                  `too-many-401-${this.consecutive401}-${this.dep.router.currentRoute.value.path}`,
                )
                return Promise.resolve(<any>response)
              }

              const refreshResult = await LoginManager.forceRefreshIfPossible()
              if (refreshResult === RefreshResult.NothingToDo) {
                return Promise.resolve(<any>response)
              }

              // if the refresh of the token was successful, we need to reload the page as we can't retry the failed request with the new token
              if (refreshResult === RefreshResult.Refreshed) {
                window.location.reload()

                return Promise.resolve(<any>response)
              }

              if (refreshResult === RefreshResult.SoftDisconnect) {
                this.dep.store.commit(
                  COMMIT_SOFT_DISCONNECT,
                  `automatic-401-response-${this.dep.router.currentRoute.value.path}`,
                )
              } else if (refreshResult === RefreshResult.HardDisconnect) {
                this.dep.store.commit(
                  COMMIT_HARD_DISCONNECT,
                  `automatic-401-response-${this.dep.router.currentRoute.value.path}`,
                )
              }

              return Promise.resolve(<any>response)
            } else if (response.status === 500) {
              console.error(response.response)
              this.consecutive401 = 0
              showUnreachableAPIAlert(responseOriginal)
              return Promise.resolve(<any>response)
            }

            this.consecutive401 = 0
            throw response
          }

          this.consecutive401 = 0
          return Promise.resolve(<any>response)
        })
    }

    protected transformOptions(options: any): Promise<any> {
      options.headers = {
        // 'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest', // mandatory so that if not authenticated, it returns 401 and not html
        ...options.headers,
      }

      const fiftySource = FiftySourceHelper.fiftySource()
      if (fiftySource) {
        options.headers['fifty-source'] = fiftySource
      }

      const accessToken = LoginManager.getAccessToken()
      if (accessToken) {
        options.headers.Authorization = `Bearer ${accessToken}`
      }

      const userId = LoginManager.getUserId()
      if (userId) {
        options.headers['user-id'] = userId
      }

      const segmentAnonymousId = LoginManager.getSegmentAnonymousId()
      if (segmentAnonymousId) {
        options.headers['segment-anonymous-id'] = segmentAnonymousId
      }

      const mfaId = LoginManager.getMfaId()
      if (this.dep.router.currentRoute?.value?.name === PAGE_LOGIN) {
        options.headers.mfaid = mfaId
      }

      options.credentials = 'omit' // important for CORS, can't be included. This implies that no cookies are sent to the api
      return Promise.resolve(options)
    }
  }
}
