import type { I18nOptions } from 'vue-i18n'

export const SHORT_DATE_DAY_OF_WEEK_FORMAT = 'shortDateDayOfWeek'
export const HOURS_ONLY_FORMAT = 'hourOnly'

const datetimeFormats: I18nOptions['datetimeFormats'] = {
  fr: {
    [SHORT_DATE_DAY_OF_WEEK_FORMAT]: { month: 'long', day: 'numeric', weekday: 'long' },
    [HOURS_ONLY_FORMAT]: { hour: 'numeric' },
  },
  en: {
    [SHORT_DATE_DAY_OF_WEEK_FORMAT]: { month: 'long', day: 'numeric', weekday: 'long' },
    [HOURS_ONLY_FORMAT]: { hour: 'numeric', hour12: true },
  },
  es: {
    [SHORT_DATE_DAY_OF_WEEK_FORMAT]: { month: 'long', day: 'numeric', weekday: 'long' },
    [HOURS_ONLY_FORMAT]: { hour: 'numeric' },
  },
  de: {
    [SHORT_DATE_DAY_OF_WEEK_FORMAT]: { month: 'long', day: 'numeric', weekday: 'long' },
    [HOURS_ONLY_FORMAT]: { hour: 'numeric' },
  },
}

export default datetimeFormats
