import type { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'

export enum FiftyEnvType {
  Development,
  PreProduction,
  Production,
}

export interface IDataFetching {
  isLoading: boolean
  hasError?: boolean
  isForbidden?: boolean
}

export class DataFetching implements IDataFetching {
  isLoading = true
  hasError?: boolean = false
  isForbidden?: boolean = false
}

export class Challenge {
  data!: FiftyApiClients.ChallengeDataDto
  usage?: FiftyApiClients.ChallengeUsageDto | null
  groups!: FiftyApiClients.UserTeamLightDto[]
  tags!: FiftyApiClients.ChallengeUserTag[]
  programAction!: FiftyApiClients.ProgramActionLightDto
  programActionId!: string

  constructor(challengeDto: FiftyApiClients.ChallengeDto, programAction: FiftyApiClients.ProgramActionLightDto) {
    this.data = challengeDto.data!
    this.usage = challengeDto.usage
    this.groups = challengeDto.groups!
    this.tags = challengeDto.tags!
    this.programAction = programAction
    this.programActionId = programAction.programActionId
  }
}
