import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import RelativeTime from 'dayjs/plugin/relativeTime'
import useLocale from '@/hooks/useLocale'

const { currentLocale } = useLocale()

dayjs.locale(currentLocale.value)
dayjs.extend(LocalizedFormat)
dayjs.extend(RelativeTime)

const DAY_MONTH_YEAR_LONG = 'LL'

// WARNING Better to use the hook useDate insead of this class..
// this class still exists only for the components in OPTIONS API

export default class DateService {
  static readonly TIME = 'LT'
  static readonly DAY_MONTH_YEAR_SLASH = 'L'
  static readonly DAY_MONTH_YEAR_HOUR_MINUTES = 'lll'
  static readonly DAY_MONTH_YEAR_HOUR_MINUTES_SHORT = 'L LT'
  static readonly DAY_MONTH_YEAR_LONG = DAY_MONTH_YEAR_LONG
  static readonly DAY_MONTH_YEAR_SHORT = 'll'

  public format(date: Date, format?: string): string {
    if (date < new Date('2000-01-01')) return ''

    dayjs.locale(currentLocale.value)
    dayjs.extend(LocalizedFormat)
    return dayjs(date).format(format || DAY_MONTH_YEAR_LONG)
  }

  public static isMondayFirstDayOfWeek(): boolean {
    dayjs.locale(currentLocale.value)
    return dayjs().localeData().firstDayOfWeek() === 1
  }
}
