import type { Logger } from '@/store/services/Logger'

export function initErrorHandling(logger: Logger, window: Window): void {
  window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
    // Caused by LastPass extension
    if (errorMsg.toString() === 'ResizeObserver loop completed with undelivered notifications.') {
      return
    }

    console.trace()
    console.error(errorMsg)

    const payload = JSON.stringify(errorObj)
    const pageUrl = window.location ? window.location.href : 'unknown'
    logger.logError(errorMsg.toString(), payload, pageUrl, url, lineNumber, column)
  }
}
