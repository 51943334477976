<template>
  <img class="fifty-spinner" :style="{ width: props.width }" src="/img/loading/fifty-loader-violet.svg" />
</template>

<script setup lang="ts">
const props = defineProps({
  width: {
    type: String,
    default: undefined,
  },
})
</script>
