import { PAGE_FORBIDDEN, PAGE_FORCE_LOGOUT } from '@/router/routes'
import { $apiClients, $logger, $segment } from '@/globalProperties'
import FiftySourceHelper from '@/store/services/FiftySourceHelper'
import { PushHelper } from '@/store/services/PushHelper'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import CookieHelper from '@/store/services/CookieHelper'
import ExpiredLicenseGuard from '@/router/guards/ExpiredLicenseGuard'
import AuthGuard, { isAuthRoute } from '@/router/guards/AuthGuard'
import BackofficeGuard, { isBackofficeRoute } from '@/router/guards/BackofficeGuard'
import AdminGuard, { isAdminRoute } from '@/router/guards/AdminGuard'
import EmployeeGuard, { isEmployeeRoute } from '@/router/guards/EmployeeGuard'
import store from '@/store/store'
import LoginManager, { RefreshResult } from '@/store/services/LoginManager'
import router from '../router'
import LocaleGuard from '@/router/guards/LocaleGuard'

function cookieAndUserToken(to: RouteLocationNormalized) {
  // Cookies & User Token
  if (to.query.s) {
    CookieHelper.setCookie(FiftySourceHelper.fiftySourceKey, to.query.s as string)
  }
  PushHelper.saveAppToken(to.query['app-token'] as string, to.query['mobile-id'] as string, $logger)
}

export default async (to: RouteLocationNormalized, from: RouteLocationNormalized | null, next: NavigationGuardNext) => {
  cookieAndUserToken(to)
  // GUARDS
  let redirect

  if (!store.state?.Login?.isInit) {
    await LoginManager.initLogin($apiClients, router, $logger, store, $segment)
    if (to.name === PAGE_FORCE_LOGOUT) {
      await LoginManager.handleInitialLogin(RefreshResult.HardDisconnect)
    }
  }

  redirect = LocaleGuard.checkLocale(to, from)
  if (redirect) {
    return next(redirect)
  }

  if (isAuthRoute(to)) {
    redirect = AuthGuard.userConnected(to, from)
    if (redirect) {
      return next(redirect)
    }
  }

  if (isBackofficeRoute(to)) {
    redirect = BackofficeGuard.allowAccess(to)
    if (redirect) {
      return next(redirect)
    }
  }

  if (isAdminRoute(to)) {
    redirect = ExpiredLicenseGuard.adminAccessActiveLicense(to, from)
    if (redirect) {
      return next(redirect)
    }
    redirect = AdminGuard.allowAccess(to)
    if (redirect) {
      return next(redirect)
    }
  }

  if (isEmployeeRoute(to) && to.name !== PAGE_FORBIDDEN) {
    redirect = ExpiredLicenseGuard.userAccessActiveLicense(to, from)
    if (redirect) {
      return next(redirect)
    }
    redirect = await EmployeeGuard.redirect(to, from)

    if (redirect) {
      return next(redirect)
    }
  }

  return next()
}
