import { DateHelper } from '@/store/services/UtilsService'
import type { Logger } from '@/store/services/Logger'
import type { Router } from 'vue-router'
import SsoLoginManager from '@/store/services/SsoLoginManager'
import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import ReactNativeMessages, { VuejsToReactNativeType } from './ReactNativeMessages'
import type { Store } from 'vuex'
import { PAGE_FORCE_LOGOUT, PAGE_LOGIN } from '@/router/routes'
import {
  COMMIT_HARD_DISCONNECT,
  COMMIT_SOFT_DISCONNECT,
  DISPATCH_INIT_USER_DATA,
  type IInitUserPayload,
} from '../modules/Login'
import type { FiftyApiClientManager } from './apiClient/FiftyApiClientManager'

export enum RefreshResult {
  Refreshed = 0,
  SoftDisconnect = 1,
  HardDisconnect = 2,
  NothingToDo = 3,
}

export const HAS_TRIED_TO_LOG_WITH_SSO_COUNT_KEY = 'hasTriedToLogWithSsoCount'

// WITH TEAMS
export const USER_LOGGED_WITH_TEAMS_KEY = 'userLoggedWithTeams'
export const USER_WITH_TEAMS_PARAMS_KEY = 'userWithTeamsParams'

export default class LoginManager {
  private static readonly accessTokenKey: string = 'access-token'
  private static readonly refreshTokenKey: string = 'refresh-token'
  private static readonly accessTokenExpirationKey: string = 'access-token-expiration'
  private static readonly userIdKey: string = 'user-id'
  private static readonly segmentAnonymousIdKey: string = 'ajs_anonymous_id'
  private static readonly segmentAnonymousIdKey2: string = '__anon_id'
  private static readonly mfaIdKey: string = 'mfaid'
  private static apiClients: FiftyApiClientManager | null = null
  private static accountApiClient: FiftyApiClients.AccountApiClient | null = null
  private static logger: Logger | null = null
  private static router: Router | null = null
  private static store: Store<any> | null = null
  private static segment: any = null
  private static refreshInterval: any = null

  static async initLogin(
    apiClients: FiftyApiClientManager,
    router: Router,
    logger: Logger,
    store: Store<any>,
    segment: any,
  ): Promise<RefreshResult> {
    this.apiClients = apiClients
    this.accountApiClient = apiClients.accountApiClient
    this.logger = logger
    this.router = router
    this.store = store
    this.segment = segment

    const res = await this.ensureTokenIsRefreshed()

    if (this.isLoggedIn()) {
      this.startAutoRefresh()
    }

    await this.handleInitialLogin(res)

    return res
  }

  static async handleInitialLogin(refreshResult: RefreshResult): Promise<RefreshResult> {
    const isForceLogoutUrl = this.router?.currentRoute.value.name === PAGE_FORCE_LOGOUT

    if (isForceLogoutUrl || refreshResult === RefreshResult.HardDisconnect) {
      await this.store?.commit(COMMIT_HARD_DISCONNECT, isForceLogoutUrl ? 'logout-page' : 'automatic-from-app.ts')
    } else if (refreshResult === RefreshResult.SoftDisconnect || !LoginManager.isLoggedIn()) {
      await this.store?.commit(COMMIT_SOFT_DISCONNECT, 'automatic-from-app.ts')
    } else {
      await this.store?.dispatch(DISPATCH_INIT_USER_DATA, {
        router: this.router,
        apiClients: this.apiClients,
        segment: this.segment,
      } as IInitUserPayload)
    }

    return refreshResult
  }

  static async ensureTokenIsRefreshed(): Promise<RefreshResult> {
    if (!this.shouldRenewAccessTokenWithExpiration()) {
      return RefreshResult.NothingToDo
    }

    const result = await this.refreshAccessToken()

    return result
  }

  static async forceRefreshIfPossible(): Promise<RefreshResult> {
    if (!this.hasMandatoryConditionsToRefresh()) return RefreshResult.NothingToDo

    const result = await this.refreshAccessToken()
    return result
  }

  static async refreshAccessToken(): Promise<RefreshResult> {
    const userId = this.getUserId()
    const refreshToken = this.getRefreshToken()
    this.logger?.logInformation(`About to start refresh for user ${userId}`)
    try {
      const currentRoute = this.router?.currentRoute.value.fullPath
      if (!currentRoute) {
        return RefreshResult.SoftDisconnect
      }

      const res = await this.accountApiClient!.refreshLogin(userId!, refreshToken!, currentRoute?.toString())

      if (res === null || !res.result) {
        this.logger?.logWarning(
          `Couldn't renew connection for user ${userId} as the return from refresh login is null.`,
        )
        return RefreshResult.SoftDisconnect
      }

      if (res.result.ssoLogin) {
        this.logger?.logInformation(
          `Direct refresh login is not possible as user ${userId} uses SSO, redirecting user to appropriate url.`,
        )
        SsoLoginManager.RedirectToSso(res.result.ssoLogin)
        return RefreshResult.Refreshed
      }

      if (res.result.loginResponse) {
        const loginResponse = res.result.loginResponse!

        if (!loginResponse.loginAccess || loginResponse.loginFailReason !== FiftyApiClients.LoginFailReasonDto.None) {
          this.logger?.logInformation(
            `Couldn't renew connection for user ${userId}, reason: ${loginResponse.loginFailReason}.`,
          )
          return RefreshResult.HardDisconnect
        }

        this.setLoggedIn(loginResponse.userId!, loginResponse.loginAccess)
        this.logger?.logInformation(`Connection was refreshed for user ${userId}.`)
        return RefreshResult.Refreshed
      }

      return RefreshResult.SoftDisconnect
    } catch (e) {
      this.logger?.logError(`Exception while trying to refresh the token for user ${userId}.`, e as string)
      return RefreshResult.SoftDisconnect
    }
  }

  static isLoggedIn(): boolean {
    return !!this.getAccessToken()
  }

  static getAccessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey)
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey)
  }

  static hasRefreshToken(): boolean {
    return !!this.getRefreshToken()
  }

  static getUserId(): string | null {
    return localStorage.getItem(this.userIdKey)
  }

  static getSegmentAnonymousId(): string | null {
    const anon1 = localStorage.getItem(this.segmentAnonymousIdKey)
    if (anon1) return anon1

    const anon2 = localStorage.getItem(this.segmentAnonymousIdKey2)
    return anon2
  }

  static getMfaId(): string | null {
    return localStorage.getItem(this.mfaIdKey)
  }

  static hasMandatoryConditionsToRefresh(): boolean {
    const userId = localStorage.getItem(this.userIdKey)
    if (!this.hasRefreshToken()) {
      this.logger?.logInformation(`No refresh as there is no refresh token for user ${userId}`)
      return false
    }

    if (!this.router?.currentRoute.value.meta.stopRefreshLogin) {
      return true
    }

    this.logger?.logInformation(`No refresh as it is a no refresh page for user ${userId}`)
    return false
  }

  static shouldRenewAccessTokenWithExpiration(): boolean {
    if (!this.hasMandatoryConditionsToRefresh()) {
      return false
    }

    return this.isAboutToExpire()
  }

  static isAboutToExpire(): boolean {
    const strExpireAt = localStorage.getItem(this.accessTokenExpirationKey)
    if (strExpireAt === null) return true // If doesn't exist, then considered as expired

    const refreshTokenThresholdInMinutes = 5
    const expireAt = new Date(strExpireAt)
    const nowPlusFewMinutes = DateHelper.addMinutes(new Date(), refreshTokenThresholdInMinutes)
    return expireAt < nowPlusFewMinutes
  }

  static setLoggedIn(userId: string, loginAccess: FiftyApiClients.ILoginAccessDto): void {
    localStorage.setItem(this.userIdKey, userId)
    localStorage.setItem(this.accessTokenExpirationKey, loginAccess.expireAt.toISOString())

    if (loginAccess.accessToken) {
      localStorage.setItem(this.accessTokenKey, loginAccess.accessToken)
    }
    if (loginAccess.refreshToken) {
      localStorage.setItem(this.refreshTokenKey, loginAccess.refreshToken)

      ReactNativeMessages.postMessage(VuejsToReactNativeType.token, { refreshToken: loginAccess.refreshToken, userId })
    }

    this.startAutoRefresh()

    this.logger?.logInformation(`Login for user ${userId}`)
  }

  static setMfaInfo(mfaId: string): void {
    localStorage.setItem(this.mfaIdKey, mfaId)
  }

  static setLoggedOut(origin = 'automatic'): void {
    const userId = localStorage.getItem(this.userIdKey)
    this.logger?.logInformation(
      `About to hard logout for user ${userId} with origin '${origin}'.`,
      JSON.stringify(localStorage),
    )
    localStorage.clear()

    this.stopAutoRefresh()

    this.logger?.logInformation(`Complete logout for user ${userId}`, JSON.stringify(localStorage))
    this.router?.push(PAGE_LOGIN)
  }

  // For this one, we keep all the data except for the access token, leaving a refresh possible
  static setSoftLogout(origin = 'automatic') {
    const userId = localStorage.getItem(this.userIdKey)

    this.logger?.logInformation(
      `About to soft logout for user ${userId} with origin '${origin}'.`,
      JSON.stringify(localStorage),
    )
    localStorage.removeItem(this.accessTokenKey)
    localStorage.setItem(this.accessTokenExpirationKey, new Date().toISOString())
  }

  static startAutoRefresh() {
    if (this.refreshInterval) return

    const refreshTokenPeriod = 120000 // 120 sec
    this.refreshInterval = setInterval(() => {
      this.ensureTokenIsRefreshed()
    }, refreshTokenPeriod)
  }

  static stopAutoRefresh() {
    if (this.refreshInterval === null) return

    clearInterval(this.refreshInterval)
    this.refreshInterval = null
  }
}
