<template>
  <router-view v-slot="{ Component }" class="fifty-router-view">
    <suspense :timeout="500">
      <template #default>
        <transition :name="props.transition" mode="out-in">
          <component :is="Component" />
        </transition>
      </template>

      <template #fallback>
        <div class="fifty-router-view__loading">
          <FiftySpinner width="100px" />
        </div>
      </template>
    </suspense>
  </router-view>
</template>

<script setup lang="ts">
import FiftySpinner from '@/components/FiftySpinner.vue'

const props = defineProps({
  transition: {
    type: String,
    default: undefined,
  },
})
</script>

<style lang="scss">
.fifty-router-view {
  .fifty-router-view__loading {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }
}
</style>
