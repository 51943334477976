import { TranslationKeys } from '@/translationKeys'
import { ref } from 'vue'
import i18n from '@/i18n'
import router from '@/router/router'

const content = ref('')
const isFiftyBannerAlertOpen = ref(false)

type RouteToSilenceForError500 = {
  '*': string[]
  metas: Record<string, string[]>
  [key: string]: string[] | Record<string, string[]>
}

const ROUTES_TO_SILENCE_FOR_ERROR_500: RouteToSilenceForError500 = {
  '*': ['/status/version', '/users/technical', '/logs/client'], // for all routes
  metas: {
    isAdmin: ['/sessions', '/habits'], // for a specific route meta
  },
  // ex: 'admin-programs': ['/sessions', '/habits'], // for a specific route name - using route.name
}

const useFiftyBannerAlert = () => {
  function isSilent(url: string) {
    const { name, meta } = router.currentRoute.value

    const routeName = name?.toString() ?? ''

    const check = [
      ...ROUTES_TO_SILENCE_FOR_ERROR_500['*'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...(Array.isArray(ROUTES_TO_SILENCE_FOR_ERROR_500[routeName]) ? ROUTES_TO_SILENCE_FOR_ERROR_500[routeName] : []),
      ...Object.keys(meta ?? {}).reduce((acc: string[], m: string) => {
        if (ROUTES_TO_SILENCE_FOR_ERROR_500.metas[m]) {
          acc.push(...ROUTES_TO_SILENCE_FOR_ERROR_500.metas[m])
        }
        return acc
      }, []),
    ]
    return check?.find((apiCallUrl) => url.includes(apiCallUrl))
  }

  function showUnreachableAPIAlert(response: Response) {
    if (!isSilent(response.url)) {
      content.value = i18n.global.t(TranslationKeys.FIFTYBANNERALERT_UNREACHABLEAPI_MESSAGE)
      isFiftyBannerAlertOpen.value = true
      setTimeout(clearAlert, 5000)
    }
  }

  function clearAlert() {
    isFiftyBannerAlertOpen.value = false
    setTimeout(() => {
      content.value = ''
    }, 1000)
  }

  return { showUnreachableAPIAlert, clearAlert, isFiftyBannerAlertOpen, content }
}

export default useFiftyBannerAlert
