import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import type { Router } from 'vue-router'
import LoginManager from '@/store/services/LoginManager'
import VersionManager from '@/store/services/VersionManager'

export class Logger {
  private logger: FiftyApiClients.LoggerApiClient
  private router: Router

  constructor(logger: FiftyApiClients.LoggerApiClient, router: Router) {
    this.logger = logger
    this.router = router
  }

  public logTrace(
    msg: string,
    payload?: string,
    pageUrl?: string,
    fileUrl?: string,
    line?: number,
    column?: number,
    stack?: string,
    errorType?: string,
  ) {
    const logRequest = this.createLog(
      FiftyApiClients.FiftyLogLevel.Trace,
      msg,
      payload,
      line,
      column,
      stack,
      errorType,
      pageUrl,
      fileUrl,
    )
    console.trace(logRequest?.logItem)
    this.logger.log(logRequest).finally()
  }

  public logDebug(
    msg: string,
    payload?: string,
    pageUrl?: string,
    fileUrl?: string,
    line?: number,
    column?: number,
    stack?: string,
    errorType?: string,
  ) {
    const logRequest = this.createLog(
      FiftyApiClients.FiftyLogLevel.Debug,
      msg,
      payload,
      line,
      column,
      stack,
      errorType,
      pageUrl,
      fileUrl,
    )
    console.debug(logRequest?.logItem)
    this.logger.log(logRequest).finally()
  }

  public logInformation(
    msg: string,
    payload?: string,
    pageUrl?: string,
    fileUrl?: string,
    line?: number,
    column?: number,
    stack?: string,
    errorType?: string,
  ) {
    const logRequest = this.createLog(
      FiftyApiClients.FiftyLogLevel.Information,
      msg,
      payload,
      line,
      column,
      stack,
      errorType,
      pageUrl,
      fileUrl,
    )
    this.logger.log(logRequest).finally()
  }

  public logWarning(
    msg: string,
    payload?: string,
    pageUrl?: string,
    fileUrl?: string,
    line?: number,
    column?: number,
    stack?: string,
    errorType?: string,
  ) {
    const logRequest = this.createLog(
      FiftyApiClients.FiftyLogLevel.Warning,
      msg,
      payload,
      line,
      column,
      stack,
      errorType,
      pageUrl,
      fileUrl,
    )
    console.warn(logRequest?.logItem)
    this.logger.log(logRequest).finally()
  }

  public logError(
    msg: string,
    payload?: string,
    pageUrl?: string,
    fileUrl?: string,
    line?: number,
    column?: number,
    stack?: string,
    errorType?: string,
  ) {
    const logRequest = this.createLog(
      FiftyApiClients.FiftyLogLevel.Error,
      msg,
      payload,
      line,
      column,
      stack,
      errorType,
      pageUrl,
      fileUrl,
    )
    console.error(logRequest?.logItem, payload)
    this.logger.log(logRequest).finally()
  }

  private createLog(
    logLevel: FiftyApiClients.FiftyLogLevel,
    msg: string,
    payload?: string,
    line?: number,
    column?: number,
    stack?: string,
    errorType?: string,
    pageUrl?: string,
    fileUrl?: string,
  ): FiftyApiClients.LogRequest {
    const logRequest = new FiftyApiClients.LogRequest()
    const logItem = new FiftyApiClients.LogItem()
    logItem.level = logLevel
    logItem.time = new Date(new Date().getTime())
    logItem.version = VersionManager.getFrontendLocalVersion()
    logItem.userId = LoginManager.getUserId()
    if (!logItem.userId) {
      logItem.userId = localStorage.getItem('ajs_user_id')
    }
    logItem.anonymousUserId = localStorage.getItem('ajs_anonymous_id')

    const logItemMessage = new FiftyApiClients.LogItemMessage()
    logItemMessage.message = msg ?? 'unknown'
    logItemMessage.line = line ?? -1
    logItemMessage.column = column ?? -1
    logItemMessage.stack = stack ?? 'unknown'
    logItemMessage.errorType = errorType ?? 'unknown'
    logItemMessage.pageUrl = pageUrl ?? this.getPageUrl()
    logItemMessage.fileUrl = fileUrl ?? 'unknown'
    logItemMessage.payload = payload ?? 'unknown'

    logItem.message = logItemMessage
    logRequest.logItem = logItem
    return logRequest
  }

  private getPageUrl(): string {
    if (this.router.currentRoute) return this.router.currentRoute.value.fullPath

    return 'unknown'
  }
}
