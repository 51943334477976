import type { RouteLocationNormalized } from 'vue-router'
import { $segment } from '@/globalProperties'
import { TrackEventType } from '@/store/services/SegmentHelper'

const DEFAULT_PAGE_TITLE = 'Fifty'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (to.meta.title) {
    document.title = (to.meta.title as string) || DEFAULT_PAGE_TITLE
  }
  if (to.name) {
    $segment.pageHasLoaded(to.name as string, to.params)

    $segment.track(TrackEventType.pageView, {
      pageName: to.name,
      pageFrom: from.name,
      isAdminInterface: !!to.meta.isAdmin,
      isBackofficeInterface: !!to.meta.isBackoffice,
      isEmployeeInterface: !(to.meta.isAdmin || to.meta.isBackoffice),
    })
  }
}
