export default class CookieHelper {
  public static setCookie(key: string, value: string, durationBeforeExpirationInDays = 365): void {
    const d = new Date()
    d.setTime(d.getTime() + durationBeforeExpirationInDays * 24 * 60 * 60 * 1000)
    document.cookie = `${key}=${value};expires=${d.toUTCString()};path=/`
  }

  public static removeCookie(key: string): void {
    const d = new Date()
    document.cookie = `${key}=;expires=${d.toUTCString()};path=/`
  }

  public static getCookie(key: string): string | undefined {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${key}=`)

    if (parts.length !== 2) return undefined

    const pop = parts.pop()
    if (!pop) return undefined
    return pop.split(';').shift()
  }
}
