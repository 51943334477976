import { createApp } from 'vue'
import 'bootstrap/scss/bootstrap.scss'
import 'styles/_site_shared.scss'
// Design system
import 'styles/app/_fifty_all_design_system.scss'
import 'styles/_fonts.scss'
import 'styles/_toast.scss'
import 'styles/_v-tooltip_default.scss'
import 'styles/fifty_global_styles.scss'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'element-closest-polyfill'
import router from './router/router'
import store from './store/store'
import i18n from './i18n'
import initSentry from '@/vendors/sentry'
import TabActivityMonitor from '@/store/services/TabActivityMonitor'
import { initErrorHandling } from '@/store/services/AppInit'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createHead } from '@vueuse/head'
import VersionManager from '@/store/services/VersionManager'
import cssVars from 'css-vars-ponyfill'
import smoothscroll from 'smoothscroll-polyfill'
import windowUtils from '@/utils/window'
import emitter from '@/utils/emitter'
import FiftyRouterView from '@/layouts/FiftyRouterView.vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import VueformToggle from '@vueform/toggle'
import '@vueform/toggle/themes/default.css'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import '@/icons/fortawesome_index'
import VueSmoothScroll from 'vue3-smooth-scroll'
import { $apiClients, $challengeHelper, $dateService, $logger, $obTransitionHelper, $segment } from '@/globalProperties'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import SanitizeHtmlDirective from '@/components/directives/SanitizeHtml'
import SegmentTrackDirective from '@/components/directives/SegmentTrack'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'bootstrap'
import BeforeEachGuard from '@/router/guards/BeforeEachGuard'
import AfterEachGuard from '@/router/guards/AfterEachGuard'
import useLocale from '@/hooks/useLocale'

smoothscroll.polyfill()
const head = createHead()
const app = createApp(FiftyRouterView)

// Router global guards
router.beforeEach(BeforeEachGuard)
router.afterEach(AfterEachGuard)

app.directive('sanitize-html', SanitizeHtmlDirective)
app.directive('segment-track', SegmentTrackDirective)

app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('DatePicker', Datepicker)
app.component('VueformToggle', VueformToggle)

app.use(i18n)
app.use(store)
app.use(router)
app.use(head)
app.use(VueTelInput)
app.use(VueSmoothScroll)
app.use(VueVirtualScroller)

app.config.globalProperties.$apiClients = $apiClients
app.config.globalProperties.$logger = $logger
app.config.globalProperties.$segment = $segment
app.config.globalProperties.$windowUtils = windowUtils
app.config.globalProperties.$emitter = emitter
app.config.globalProperties.$env = window.env
app.config.globalProperties.$challengeHelper = $challengeHelper
app.config.globalProperties.$dateService = $dateService
app.config.globalProperties.$obTransitionHelper = $obTransitionHelper

cssVars({
  // Ponyfill to legacy browsers dont supporting css vars
  onlyLegacy: true,
  preserveVars: true,
  watch: true,
  onComplete: function (cssText, styleElms, cssVariables) {
    window.cssVariables = cssVariables
  },
})

initErrorHandling($logger, window)

initSentry(app, router)

const versionManager = new VersionManager($apiClients)
versionManager.checkVersion()

const tabActivityMonitor = new TabActivityMonitor($logger, store, router, $segment, $apiClients, versionManager)
const { loadRemoteTranslation, loadLocalTranslations } = useLocale()

loadRemoteTranslation()
loadLocalTranslations()

app.mount('#app-container')

console.debug('timeout before version check value:', `${tabActivityMonitor.minTimeBeforeRefreshingInMs / 1000 / 60}min`)
