import {
  PAGE_ON_BOARDING_ACCEPT_CHALLENGE,
  PAGE_ON_BOARDING_CHALLENGE_CHOICE,
  PAGE_ON_BOARDING_CONGRATULATIONS,
  PAGE_ON_BOARDING_COURSE_DESCRIPTION,
  PAGE_ON_BOARDING_HOW_TO,
  PAGE_ON_BOARDING_LEARNING_METHOD,
  PAGE_ON_BOARDING_PILLAR_SELECT,
  PAGE_ON_BOARDING_SEGMENTS,
} from '@/router/routes'
import type { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'

export enum OnBoardingGroupStep {
  Unknown = 0,
  Welcome = 1,
  Segments = 2,
  PillarSelect = 3,
  FirstChallenge = 4,
}

export class OnBoardingHelper {
  public static campaignCodeKey = 'onBoarding.campaignCode'

  public static getStepIndex(currentPageName: string, allSteps: string[]): number {
    return allSteps.findIndex((a) => a === currentPageName)
  }

  public static getAllSteps(onBoardingData: FiftyApiClients.OnBoardingDataDto): string[] {
    const steps: string[] = []

    steps.push(PAGE_ON_BOARDING_COURSE_DESCRIPTION)
    steps.push(PAGE_ON_BOARDING_LEARNING_METHOD)
    steps.push(PAGE_ON_BOARDING_HOW_TO)
    if (!onBoardingData.stepSummary?.shouldSkipSegments) {
      steps.push(PAGE_ON_BOARDING_SEGMENTS)
    }

    steps.push(PAGE_ON_BOARDING_PILLAR_SELECT)
    steps.push(PAGE_ON_BOARDING_CHALLENGE_CHOICE)

    return steps
  }

  public static getStepTranslatedKeyByGroupStep(currentPage: string): OnBoardingGroupStep {
    switch (currentPage) {
      case PAGE_ON_BOARDING_COURSE_DESCRIPTION:
      case PAGE_ON_BOARDING_LEARNING_METHOD:
      case PAGE_ON_BOARDING_HOW_TO:
        return OnBoardingGroupStep.Welcome

      case PAGE_ON_BOARDING_SEGMENTS:
        return OnBoardingGroupStep.Segments

      case PAGE_ON_BOARDING_PILLAR_SELECT:
        return OnBoardingGroupStep.PillarSelect

      case PAGE_ON_BOARDING_CHALLENGE_CHOICE:
      case PAGE_ON_BOARDING_ACCEPT_CHALLENGE:
      case PAGE_ON_BOARDING_CONGRATULATIONS:
        return OnBoardingGroupStep.FirstChallenge
    }

    return OnBoardingGroupStep.Unknown
  }

  public static getStepIndexByGroupStep(currentPage: string, shouldSkipSegments?: boolean): number {
    switch (currentPage) {
      case PAGE_ON_BOARDING_COURSE_DESCRIPTION:
      case PAGE_ON_BOARDING_LEARNING_METHOD:
      case PAGE_ON_BOARDING_HOW_TO:
        return OnBoardingGroupStep.Welcome

      case PAGE_ON_BOARDING_SEGMENTS:
        return OnBoardingGroupStep.Segments

      case PAGE_ON_BOARDING_PILLAR_SELECT:
        if (shouldSkipSegments) {
          return OnBoardingGroupStep.PillarSelect - 1
        }
        return OnBoardingGroupStep.PillarSelect

      case PAGE_ON_BOARDING_CHALLENGE_CHOICE:
      case PAGE_ON_BOARDING_ACCEPT_CHALLENGE:
      case PAGE_ON_BOARDING_CONGRATULATIONS:
        if (shouldSkipSegments) {
          return OnBoardingGroupStep.FirstChallenge - 1
        }
        return OnBoardingGroupStep.FirstChallenge
    }

    return OnBoardingGroupStep.Unknown
  }
}
