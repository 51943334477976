import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import type { Logger } from '@/store/services/Logger'
import type { Router } from 'vue-router'
import { PAGE_SSO_LOGIN_REDIRECT } from '@/router/routes'
import ReactNativeMessages, { VuejsToReactNativeType } from './ReactNativeMessages'

export default class SsoLoginManager {
  private static logger: Logger | null = null
  private static router: Router | null = null

  static init(logger: Logger, router: Router) {
    this.logger = logger
    this.router = router
  }

  static RedirectToSso(
    ssoRedirection: FiftyApiClients.SsoRedirectionDto | null | undefined,
    email?: string,
    showSafariBrowser = false,
  ): boolean {
    if (!ssoRedirection) return false

    if (ssoRedirection.bindingType === FiftyApiClients.SsoBindingTypeDto.Redirect) {
      // SsoBindingTypeDto.Redirect
      this.logger?.logInformation(`About to GET redirect user for sso login to ${ssoRedirection.url}`)
      if (!ssoRedirection.url) {
        this.logger?.logError(`Sso redirection url is null`)
        return false
      }

      window.location.href = ssoRedirection.url
      return true
    }

    if (ssoRedirection.bindingType === FiftyApiClients.SsoBindingTypeDto.Post) {
      if (showSafariBrowser) {
        ReactNativeMessages.postMessage(VuejsToReactNativeType.login, {
          sso: {
            shouldOpenSafari: true,
            email,
          },
        })
      }

      // SsoBindingTypeDto.Post
      // We can't pass those variable as query arguments as it exceeds the length limit on some browsers (ie11...)
      localStorage.setItem('sso.samlRequest', ssoRedirection.samlRequest!)
      localStorage.setItem('sso.relayState', ssoRedirection.relayState!)
      localStorage.setItem('sso.r', ssoRedirection.url!)
      this.router?.push({ name: PAGE_SSO_LOGIN_REDIRECT })
      return true
    }

    this.logger?.logError(
      `Unknown Sso saml binding ${ssoRedirection.bindingType} r: ${ssoRedirection.url} and relay state: ${ssoRedirection.relayState}`,
    )
    return false
  }
}
