import type { RouteLocationNormalized } from 'vue-router'
import { PAGE_LOGIN, PAGE_SESSIONS_ON_GOING_CHALLENGES } from '@/router/routes'
import LoginManager from '@/store/services/LoginManager'

export const isAuthRoute = (route: RouteLocationNormalized) => route.matched.some((m) => m.meta.requiresAuth)

export default class AuthGuard {
  public static userConnected(to: RouteLocationNormalized, from: RouteLocationNormalized | null) {
    if (LoginManager.isLoggedIn() || to.name === PAGE_LOGIN || to.path === '/login') {
      return null
    }

    if (to.matched.some((record) => record.meta.loggedOutOnly)) {
      if (LoginManager.isLoggedIn()) {
        return { name: PAGE_SESSIONS_ON_GOING_CHALLENGES }
      }
    }

    return { name: PAGE_LOGIN, query: { r: to.fullPath, ...to.query } }
  }
}
