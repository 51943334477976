import { DISPATCH_REFRESH_USER } from '@/store/modules/Login'
import { DISPATCH_LOAD_SESSIONS_DATA } from '@/store/modules/ChallengeSession'

export default class ObTransitionHelper {
  public static readonly MINIMUM_TIME_SHOWING_TRANSITION = 4.5 * 1000 // seconds * 1000
  public static beginTransitionTime: number
  public static timeLapsed: number

  /**
   * set 100% to progressbar, hide it, and hide back btn
   */
  public navbarTransitions(): void {
    ObTransitionHelper.beginTransitionTime = new Date().getTime()
    const menu = document.getElementById('menu-container') as HTMLElement
    if (menu) {
      menu.classList.add('ob-end')
      setTimeout(() => {
        menu.classList.add('no-progress-bar')
      }, 1000)
    }
  }

  /**
   * refresh data and return time lapsed to request api
   * @param store
   * @param apiClients
   * @param segment
   */
  public async initAppAfterOB(store: any, apiClients: any, segment: any) {
    await store.dispatch(DISPATCH_REFRESH_USER, { apiClients, segment })
    await store.dispatch(DISPATCH_LOAD_SESSIONS_DATA, { apiClients })
    ObTransitionHelper.timeLapsed = new Date().getTime() - ObTransitionHelper.beginTransitionTime
    return ObTransitionHelper.timeLapsed > ObTransitionHelper.MINIMUM_TIME_SHOWING_TRANSITION
  }

  /**
   * last step back btn is conditional to hide it during ob end transition
   */
  public showBackBtnForLastStep() {
    document.getElementById('last-ob-step')?.style.setProperty('display', 'block')
  }
}
