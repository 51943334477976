import type { Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import * as Sentry from '@sentry/browser'
import type { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import useLocale, { SupportedLanguageDto } from '@/hooks/useLocale'

const DEMO_ACCOUNT_FR_ID = '8e2c0c43-d1dd-4e09-acb7-ffbdcd07c435'
const DEMO_ACCOUNT_EN_ID = '47ec8911-f2a6-4e3a-b1c8-f3cf8aa29645'
const DEMO_ACCOUNT_IDS = [DEMO_ACCOUNT_FR_ID, DEMO_ACCOUNT_EN_ID]

const { setLocale, getLanguageStringFromDto } = useLocale()
const currentUser: Ref<FiftyApiClients.DetailedUserDto | FiftyApiClients.UserDto | null> = ref(null)
const localStorageClientId = ref(localStorage.getItem('client-selector'))
const currentUserClientId = computed(() => localStorageClientId.value || currentUser.value?.client?.id || '')
const isDemoClient = computed(() =>
  currentUserClientId.value ? DEMO_ACCOUNT_IDS.includes(currentUserClientId.value) : false,
)

const useUser = () => {
  watch(
    localStorageClientId,
    (clientId) => {
      localStorage.setItem('client-selector', clientId || '')
    },
    { immediate: false },
  )

  // Because actually the back-end is still messy, we put an OR between :
  // - currentUser.value?.userRights?.canAccessAdmin
  // - dashboardRights
  // because actually the back-end is still messy
  //
  // TODO after fixing backend, replace by an AND
  const canAccessAdmin = computed(
    () =>
      !!currentUser.value?.userRights?.canAccessAdmin ||
      !!currentUser.value?.userRights?.dashboardRights?.canAccessActions ||
      !!currentUser.value?.userRights?.dashboardRights?.canAccessUsers ||
      !!currentUser.value?.userRights?.dashboardRights?.canAccessResults ||
      !!currentUser.value?.userRights?.dashboardRights?.canAccessActionRepository,
  )
  const canAccessOtherClients = computed(() => currentUser.value?.userRights?.canAccessOtherClients)
  const isAuthenticated = computed(
    () =>
      currentUser.value &&
      currentUser.value.authenticationType !== null &&
      currentUser.value.authenticationType !== undefined,
  )

  const setCurrentUser = (newUser: FiftyApiClients.DetailedUserDto | null) => {
    currentUser.value = newUser
    setLocale(getLanguageStringFromDto(newUser?.language as SupportedLanguageDto))
  }

  const setCurrentUserClientId = (clientId: string) => {
    localStorageClientId.value = clientId
  }

  const trackCurrentUser = (user: FiftyApiClients.DetailedUserDto) => {
    Sentry.setUser({ id: user.id })
  }
  const unTrackCurrentUser = () => {
    Sentry.setUser(null)
  }

  watch(currentUser, (user) => (user ? trackCurrentUser(user) : unTrackCurrentUser()))

  return {
    isDemoClient,
    isAuthenticated,
    currentUser,
    currentUserClientId,
    canAccessAdmin,
    canAccessOtherClients,
    setCurrentUser,
    setCurrentUserClientId,
  }
}

export default useUser
