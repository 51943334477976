import { ref, computed, readonly } from 'vue'

const DESKTOP_WIDTH_BREAKPOINT = 992
const DESKTOP_XXL_WIDTH_BREAKPOINT = 1300

const isDesktop = ref(false)
const isDesktopXxl = ref(false)

const isMobile = computed(() => !isDesktop.value)

function defineIsDesktop() {
  isDesktop.value = window.matchMedia(`(min-width: ${DESKTOP_WIDTH_BREAKPOINT}px)`).matches
}

function defineIsDesktopXxl() {
  isDesktopXxl.value = window.matchMedia(`(min-width: ${DESKTOP_XXL_WIDTH_BREAKPOINT}px)`).matches
}

function setBreakpoints() {
  defineIsDesktop()
  defineIsDesktopXxl()
}

setBreakpoints()
window.addEventListener('resize', setBreakpoints)

export default {
  isDesktop: readonly(isDesktop),
  isMobile,
  isDesktopXxl: readonly(isDesktopXxl),
}
