import { $segment } from '@/globalProperties'
import type { TrackEventType } from '@/store/services/SegmentHelper'

export default {
  beforeMount(el: Element, { arg, value }: { arg?: string; value: TrackEventType }) {
    if (arg) {
      el.addEventListener(arg, () => $segment.track(value))
    }
  },
  beforeUnmount(el: Element, { arg, value }: { arg?: string; value: TrackEventType }) {
    if (arg) {
      el.removeEventListener(arg, () => $segment.track(value))
    }
  },
}
