import type { FiftyApiClientManager } from '@/store/services/apiClient/FiftyApiClientManager'
import { COMMIT_INNER_SAVE_CHALLENGES } from '@/store/modules/ChallengesStore'
import type { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import type { Challenge } from '@/store/FiftyTypes'
import ChallengeDtoHelper from '@/store/services/challenges/ChallengeDtoHelper'

export interface IChallengeSessionModuleState {
  isLoadingCurrentSessions: boolean
  lastSessionWithChoice: FiftyApiClients.ChallengeSessionLightDto | null
}

export interface IRetrieveSessionsPayload {
  apiClients: FiftyApiClientManager
  inBackgroundOnly: boolean
}

export const IS_FIRST_ACCEPTABLE_CHALLENGE = 'ChallengeSession/isFirstAcceptableChallenge'
export const HAS_NO_ACCEPTED_CHALLENGE = 'ChallengeSession/hasNoAcceptedChallenge'

export const CLEAR_SESSION = 'ChallengeSession/clearSessions'
export const DISPATCH_LOAD_SESSIONS_DATA = 'ChallengeSession/retrieveSessions'

export const DISPATCH_HAS_NO_ACCEPTED_CHALLENGES = 'ChallengeSession/setHasNoAcceptedChallenges'
const COMMIT_HAS_NO_ACCEPTED_CHALLENGES = 'hasNoAcceptedChallenges'

export const DISPATCH_UNSELECT_PILLAR = 'ChallengeSession/setUnselectPillar'
const COMMIT_UNSELECT_PILLAR = 'unselectPillar'

export const ChallengeSession = {
  namespaced: true,
  state: {
    isLoadingCurrentSessions: true,
    lastSessionWithChoice: null,
  } as IChallengeSessionModuleState,
  mutations: {
    clearSessions(state: IChallengeSessionModuleState) {
      state.lastSessionWithChoice = null
    },
    setLastSessionWithChoices(
      state: IChallengeSessionModuleState,
      lastSessionWithChoice: FiftyApiClients.ChallengeSessionLightDto | null,
    ) {
      state.lastSessionWithChoice = lastSessionWithChoice
    },
    setIsLoading(state: IChallengeSessionModuleState, isLoading: boolean) {
      state.isLoadingCurrentSessions = isLoading
    },
    hasNoAcceptedChallenges(state: IChallengeSessionModuleState, hasNoAcceptedChallenges: boolean) {
      if (state.lastSessionWithChoice) {
        state.lastSessionWithChoice.hasNoAcceptedChallenges = hasNoAcceptedChallenges
      }
    },
    unselectPillar(state: IChallengeSessionModuleState) {
      if (state.lastSessionWithChoice) {
        const pillardIdToUnselect = state.lastSessionWithChoice.selectedPillarId
        state.lastSessionWithChoice.selectedPillarId = null
        state.lastSessionWithChoice.pillars = state.lastSessionWithChoice.pillars?.map((p) => {
          if (p.pillarId === pillardIdToUnselect) {
            p.isSelectable = true
          }
          return p
        })
      }
    },
  },
  actions: {
    async retrieveSessions(
      { state, commit, dispatch }: any,
      payload: IRetrieveSessionsPayload,
    ): Promise<FiftyApiClients.ChallengeSessionLightDto | null | undefined> {
      if (!payload?.inBackgroundOnly) {
        commit('setIsLoading', true)
      }

      const res = await payload?.apiClients?.sessionApiClient?.getAllSessionsData()
      commit('setIsLoading', false)
      if (!res || !res.result) {
        return null
      }

      commit('setLastSessionWithChoices', res.result.lastSessionWithChoice)

      const challenges: Challenge[] = ChallengeDtoHelper.challengeDtoToChallenge(res.result.challenges!)
      commit(COMMIT_INNER_SAVE_CHALLENGES, challenges, { root: true })
      return res.result.lastSessionWithChoice
    },
    setHasNoAcceptedChallenges({ commit }: any, hasNoAcceptedChallenges: boolean) {
      commit(COMMIT_HAS_NO_ACCEPTED_CHALLENGES, hasNoAcceptedChallenges)
    },
    setUnselectPillar({ commit }: any) {
      commit(COMMIT_UNSELECT_PILLAR)
    },
  },
  getters: {
    isFirstAcceptableChallenge: (state: IChallengeSessionModuleState) => () =>
      state.lastSessionWithChoice ? state.lastSessionWithChoice.isFirstAcceptableChallenge : false,
    hasNoAcceptedChallenge: (state: IChallengeSessionModuleState) => () =>
      state.lastSessionWithChoice ? state.lastSessionWithChoice.hasNoAcceptedChallenges : false,
    lastSessionWithChoice: (state: IChallengeSessionModuleState) => () => state.lastSessionWithChoice,
  },
}
