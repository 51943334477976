declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

export const UUidHelper = {
  isUuid: (val: string): boolean => {
    const uuidRegex = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
    return UUidHelper.validateRegex(uuidRegex, val)
  },
  newUuid: (): string =>
    `${[1e7]}${-1e3}${-4e3}${-8e3}${-1e11}`.replace(/[018]/g, (c: any) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    ),
  validateRegex: (regex: string, value: string): boolean => {
    const regexChecker = new RegExp(regex)
    return regexChecker.test(value)
  },
}

export const DateHelper = {
  MMS_IN_A_DAY: 24 * 60 * 60 * 1000,
  YESTERDAY: new Date(+new Date() - 24 * 60 * 60 * 1000),

  months: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],

  getFullDate(date: Date): string {
    return `${DateHelper.getDay(date)} ${DateHelper.getFullMonth(date)} ${DateHelper.getYear(date)}`
  },

  getFullDateShort(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  },

  getDay(date: Date): string {
    return date.getDate().toString()
  },

  getFullMonth(date: Date): string {
    const month: number = date.getMonth()
    const monthWord: string = DateHelper.months[month]
    return StringHelper.ToUpperFirstLetter(monthWord)
  },

  getYear(date: Date): string {
    return date.getFullYear().toString()
  },

  addMinutes(a: Date, minutes: number): Date {
    const date = new Date(a.valueOf())
    date.setMinutes(date.getMinutes() + minutes)
    return date
  },
}

export const StringHelper = {
  EMPTY_GUID: '00000000-0000-0000-0000-000000000000',
  ToUpperFirstLetter(val: string | null | undefined): string {
    if (!val) return ''

    return val.charAt(0).toUpperCase() + val.slice(1)
  },

  sortAsc(a: string, b: string) {
    const nameA = a.toLowerCase()
    const nameB = b.toLowerCase()

    if (nameA < nameB) return -1 // sort string ascending
    if (nameA > nameB) return 1

    return 0 // default return value (no sorting)
  },

  sortAscIgnoringAccent(a: string, b: string) {
    if (!a || !b) return 0

    const nameA = a.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const nameB = b.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    return this.sortAsc(nameA, nameB)
  },

  isValidEmail(email: string): boolean {
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  },

  isNullOrEmpty(str: any): boolean {
    return !str || this.isEmpty(str)
  },

  isNullOrWhiteSpaceHtml(str: string): boolean {
    const whiteSpaceHtmlTags = ['&nbsp;', '<p>', '</p>']

    if (this.isNullOrEmpty(str)) return true

    let tmpStr = str
    whiteSpaceHtmlTags.forEach((t) => {
      tmpStr = tmpStr?.replace(t, '') ?? ''
    })

    return tmpStr.trim() === ''
  },

  isNullOrEmptyGuid(str: any): boolean {
    return this.isNullOrEmpty(str) || str === this.EMPTY_GUID
  },

  isEmpty(str: any): boolean {
    if (typeof str === 'string') {
      if (str.split(' ').join('') === '') {
        return true
      }
    }
    return false
  },

  replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(find, 'g'), replace)
  },
}

export const ArrayHelper = {
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  groupBy<TItem>(data: TItem[], key: string): { [key: string]: TItem[] } {
    return data.reduce((storage: any, item: any) => {
      // get the first instance of the key by which we're grouping
      const group = item[key]

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || []

      // add this item to its group within `storage`
      storage[group].push(item)

      // return the updated storage to the reduce function, which will then loop through the next
      return storage
    }, {}) // {} is the initial value of the storage
  },
  compareString(a: string, b: string): number {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  },
}

export const TranslationHelper = {
  getTranslationsWithFrAtFirst(translations: any[] | null | undefined): any[] | [] {
    return !translations ? [] : [...translations].sort((a, b) => (a.language > b.language ? 1 : -1))
  },
}

export const FileHelper = {
  downloadFile: (fileContent: Blob, fileName: string) => {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround
      window.navigator.msSaveBlob(fileContent, fileName)
    } else {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(fileContent)
      link.download = fileName
      link.click()
    }
  },
}
