import type { RouteLocationNormalized } from 'vue-router'
import { PAGE_BACKOFFICE_USERS } from '@/router/routes'
import useUser from '@/hooks/useUser'

export const isBackofficeRoute = (route: RouteLocationNormalized) => route.matched.some((m) => m.meta.isBackoffice)
export default class BackofficeGuard {
  public static allowAccess(to: RouteLocationNormalized) {
    const { currentUser } = useUser()

    if (!isBackofficeRoute(to)) {
      return null
    }

    if (currentUser.value?.userRights?.canAccessAdmin && to.name !== PAGE_BACKOFFICE_USERS) {
      if (currentUser.value?.currentUserCampaign === null && currentUser.value?.userCampaigns === null) {
        return { name: PAGE_BACKOFFICE_USERS }
      }
    }

    return null
  }
}
