import LoginManager from '@/store/services/LoginManager'
import type { Logger } from '@/store/services/Logger'

export class PushHelper {
  private static unsavedToken = 'unsaved-token'
  private static unsavedMobileId = 'unsaved-mobile-id'

  public static saveAppToken(pushToken: string, mobileId: string, logger: Logger) {
    const appToken = pushToken ?? localStorage.getItem(PushHelper.unsavedToken)
    const appMobileId = mobileId ?? localStorage.getItem(PushHelper.unsavedMobileId)

    if (appToken && appMobileId && LoginManager.isLoggedIn()) {
      const userId = LoginManager.getUserId() as string
      const localTokenStorageKey = `app-token-${userId}`
      if (localStorage.getItem(localTokenStorageKey) !== appToken) {
        localStorage.setItem(localTokenStorageKey, appToken)
        logger.logInformation(`New app token: ${appToken}`)
      }

      const localMobileIdStorageKey = `app-mobile-${userId}`
      if (localStorage.getItem(localMobileIdStorageKey) !== appMobileId) {
        localStorage.setItem(localMobileIdStorageKey, appMobileId)
        logger.logInformation(`New mobile id : ${appMobileId}`)
      }
    } else {
      // user not logged yet

      if (pushToken) {
        localStorage.setItem(PushHelper.unsavedToken, pushToken)
      }
      if (mobileId) {
        localStorage.setItem(PushHelper.unsavedMobileId, mobileId)
      }
    }
  }

  public static getAppToken(): string | null {
    if (!LoginManager.isLoggedIn()) return null

    const userId = LoginManager.getUserId() as string
    const localStorageKey = `app-token-${userId}`
    const appToken = localStorage.getItem(localStorageKey)
    if (appToken) return appToken

    const unsavedToken = localStorage.getItem(PushHelper.unsavedToken)
    if (unsavedToken) return unsavedToken

    return null
  }

  public static getMobileId(): string | null {
    if (!LoginManager.isLoggedIn()) return null

    const userId = LoginManager.getUserId() as string
    const localMobileIdStorageKey = `app-mobile-${userId}`
    const appToken = localStorage.getItem(localMobileIdStorageKey)
    if (appToken) return appToken

    const unsavedMobileId = localStorage.getItem(PushHelper.unsavedMobileId)
    if (unsavedMobileId) return unsavedMobileId

    return null
  }
}
