import SsoLoginManager from '@/store/services/SsoLoginManager'
import ChallengeHelper from '@/store/services/challenges/ChallengeHelper'
import ObTransitionHelper from '@/store/services/ObTransitionHelper'
import { FiftyApiClientManager } from './store/services/apiClient/FiftyApiClientManager'
import type { CustomApiClientCode } from './store/services/apiClient/CustomApiClientCode'
import { SegmentHelper } from './store/services/SegmentHelper'
import { FiftyEnv } from '@/store/services/FiftyEnvHelper'
import { Logger } from '@/store/services/Logger'
import router from './router/router'
import store from './store/store'
import emitter from '@/utils/emitter'
import DateService from '@/services/Date.service'
import EnvHelper = FiftyEnv.EnvHelper

window.env = EnvHelper.getEnvironmentVariables(window)

const baseUrl = window.env.API_URL
const config = { baseUrl } as CustomApiClientCode.IAppConfig

export const $apiClients = FiftyApiClientManager.create(config, store, router)
export const $logger = new Logger($apiClients.loggerApiClient, router)

export const $segment = new SegmentHelper($apiClients, $logger, router, store)

export const $challengeHelper = new ChallengeHelper()
export const $dateService = new DateService()
export const $obTransitionHelper = new ObTransitionHelper()
export const $emitter = emitter

SsoLoginManager.init($logger, router)
