// This class is used to send messages to React Native
// It is used to send the refresh token to React Native
// so that it can be used to refresh the access token

export enum VuejsToReactNativeType {
  logout = 'logout',
  pageLoaded = 'pageLoaded',
  email = 'email',
  token = 'token',
  login = 'login',
}

export enum ReactNativeToVuejsType {
  refreshToken = 'refreshToken',
  email = 'defaultEmail',
}

export default class ReactNativeMessages {
  public static postMessage(type: VuejsToReactNativeType, data: unknown = {}) {
    const message = JSON.stringify({
      type,
      data,
    })

    // ios
    window.ReactNativeWebView?.postMessage(message)
    // android
    document.ReactNativeWebView?.postMessage(message)
  }
}
