import type { RouteLocationNormalized, RouteRecordName } from 'vue-router'
import { PAGE_ADMIN_ACCOUNT, PAGE_ADMIN_EXPIRED_CONTRACT, PAGE_EXPIRED_LICENSE, PAGE_LOGIN } from '@/router/routes'
import useUser from '@/hooks/useUser'
import { isAdminRoute } from '@/router/guards/AdminGuard'

export const ADMIN_ACCESS_AUTHORIZED_ROUTES: RouteRecordName[] = [PAGE_ADMIN_ACCOUNT, PAGE_ADMIN_EXPIRED_CONTRACT]
export const USER_ACCESS_AUTHORIZED_ROUTES: RouteRecordName[] = [
  PAGE_LOGIN,
  PAGE_EXPIRED_LICENSE,
  PAGE_ADMIN_EXPIRED_CONTRACT,
]

export default class ExpiredLicenseGuard {
  public static adminAccessActiveLicense(to: RouteLocationNormalized, from: RouteLocationNormalized | null) {
    const { currentUser } = useUser()
    if (
      !isAdminRoute(to) ||
      ADMIN_ACCESS_AUTHORIZED_ROUTES.includes(to.name as RouteRecordName) ||
      !currentUser.value?.isClientContractExpired
    ) {
      return null
    }
    return { name: PAGE_ADMIN_EXPIRED_CONTRACT }
  }

  public static userAccessActiveLicense(to: RouteLocationNormalized, from: RouteLocationNormalized | null) {
    const { currentUser } = useUser()
    if (
      USER_ACCESS_AUTHORIZED_ROUTES.includes(to.name as RouteRecordName) ||
      !currentUser.value?.isClientContractExpired ||
      (to.meta.isBackoffice && currentUser.value?.userRights?.canAccessAdmin)
    ) {
      return null
    }
    return { name: PAGE_EXPIRED_LICENSE }
  }
}
