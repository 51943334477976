import { createI18n } from 'vue-i18n'
import datetimeFormats from '@/i18n/rules/datetimeFormats'

export const i18n = createI18n({
  datetimeFormats,
  locale: 'en',
  fallbackLocale: {
    es: ['en'],
    fr: ['en'],
    de: ['en'],
    en: ['fr'],
  },
  messages: {},
  warnHtmlInMessage: 'off',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
})

export default i18n
