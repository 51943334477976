export interface IErrorData {
  imageName: string
  title: string
  text: string
  linkPageName?: string
  pageTitle: string
}

export enum ErrorType {
  NotFound,
  Forbidden,
  Internal,
  Network,
}